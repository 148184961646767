import {localized} from '@lit/localize';
import {customElement, state} from 'lit/decorators.js';
import type {PropertyValues} from 'lit';
import {html, LitElement} from 'lit';
import {Modal} from 'flowbite';
import {bigGalleryIndexChange$, modal$} from '../stores/store-main.ts';
import {Swiper, Navigation} from 'swiper';
import {arrowSvg, crossSvg, image0} from '../helpers/elements.ts';
import type {Gallery} from '../apis/api-common.ts';
import {getBiggestImageUrl} from '../helpers/multiImage.ts';

@localized()
@customElement('element-big-gallery')
export default class ElementBigGallery extends LitElement {
  @state() gallery: Gallery[] | undefined;
  @state() activeIndex = 0;
  private modal: Modal;
  private swiper: Swiper;

  hideOnOutsideClick() {
    const ignoreElements = [
      '.swiper-button-prev-unique',
      '.swiper-button-next-unique',
      '.swiper-slide-active img',
    ];
    const handler = (event: Event) => {
      if (!modal$.getValue()) {
        this.querySelector('#element-big-gallery-modal').removeEventListener(
          'click',
          handler,
        );
        return;
      }
      const ignore = !!ignoreElements.filter((query) => {
        let equal = false;
        let contains = false;
        this.querySelectorAll(query).forEach((el) => {
          if (!equal) equal = el.isEqualNode(<Node>event.target);
        });
        if (!equal)
          this.querySelectorAll(query).forEach((el) => {
            if (!contains) contains = el.contains(<Node>event.target);
          });
        return equal || contains;
      }).length;
      if (ignore) return;
      this.querySelector('#element-big-gallery-modal').removeEventListener(
        'click',
        handler,
      );
      modal$.next(undefined);
    };
    this.querySelector('#element-big-gallery-modal').addEventListener(
      'click',
      handler,
    );
  }

  override shouldUpdate() {
    return this.gallery !== undefined;
  }

  override render() {
    return html` <div
      id="element-big-gallery-modal"
      tabindex="-1"
      class="popup top-0 z-[70] hidden h-full w-full"
    >
      <div class="overflow-hidden">
        <div
          id="swiper-big-gallery"
          class="swiper flex items-center justify-center"
        >
          <div class="swiper-wrapper items-center">
            ${this.gallery.map((photo) => {
              return html` <div
                class="swiper-slide flex h-full !w-full items-center justify-center py-[40px] sm:py-0"
              >
                <div class="sm:w-[40px]">
                  <div
                    class="big-gallery-prev-btn swiper-button-prev-unique hidden h-[40px] w-[40px] cursor-pointer items-center justify-center bg-page-main sm:flex"
                  >
                    ${arrowSvg({
                      classes: 'fill-chm-red -rotate-180 w-[24px] h-[24px]',
                    })}
                  </div>
                </div>
                <div class="px-[20px]">
                  ${this.renderImage(photo)}
                  <div
                    data-cy="gallery-mob-footer"
                    class="flex h-[101px] flex-col bg-white sm:h-[64px] sm:flex-row sm:items-center mdxx:h-[88px]"
                  >
                    <div
                      .hidden="${!photo.copyright}"
                      class="pl-[12px] pt-[12px] sm:pl-[24px] sm:pt-0 sm:text-h4 mdxx:text-sm-h4"
                    >
                      © ${photo.copyright}
                    </div>
                    <div class="ml-auto flex gap-x-[20px] p-[14px] sm:hidden">
                      <div
                        class="big-gallery-prev-btn swiper-button-prev-unique z-20 flex h-[40px] w-[40px] cursor-pointer items-center justify-center bg-page-main"
                      >
                        ${arrowSvg({
                          classes: 'fill-chm-red -rotate-180 w-[24px] h-[24px]',
                        })}
                      </div>
                      <div
                        class="big-gallery-next-btn swiper-button-next-unique z-20 flex h-[40px] w-[40px] cursor-pointer items-center justify-center bg-page-main"
                      >
                        ${arrowSvg({
                          classes: 'fill-chm-red w-[24px] h-[24px]',
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sm:w-[40px]">
                  <div
                    class="big-gallery-next-btn swiper-button-next-unique hidden h-[40px] w-[40px] cursor-pointer items-center justify-center bg-page-main sm:flex"
                  >
                    ${arrowSvg({classes: 'fill-chm-red w-[24px] h-[24px]'})}
                  </div>
                  <div
                    class="close-gallery-btn absolute right-[20px] top-0 cursor-pointer sm:right-auto"
                    @click="${() => modal$.next(undefined)}"
                  >
                    ${crossSvg('h-[17px] w-[17px] fill-white')}
                  </div>
                </div>
              </div>`;
            })}
          </div>
        </div>
      </div>
    </div>`;
  }

  renderImage(photo: Gallery) {
    if (photo.urls) {
      const defaultSrc = getBiggestImageUrl(photo.urls);
      return html`
        <img
          class="max-h-big-gallery !w-auto"
          src="${defaultSrc}"
          srcset="
            ${Object.entries(photo.urls)
              .map(
                ([size, src]: [string, string]) =>
                  `${src} ${Number(size.split('X')[0])}w`,
              )
              .join(', ')}
          "
          sizes="100vw"
          alt=""
          loading="lazy"
        />
      `;
    } else {
      return html`
        <img
          class="max-h-big-gallery !w-auto"
          src="${image0(
            photo.srcBig ||
              photo.src ||
              getBiggestImageUrl(photo.urls) ||
              photo.urlMaster,
          )}"
          alt=${photo.abstract || ''}
          loading="lazy"
        />
      `;
    }
  }

  showModal() {
    this.modal.show();
    setTimeout(() => this.hideOnOutsideClick(), 500);
  }

  override firstUpdated() {
    this.modal = new Modal(this.querySelector('#element-big-gallery-modal'), {
      placement: 'center',
      backdropClasses:
        'big-gallery-backdrop modal-backdrop bg-black bg-opacity-75 fixed inset-0 z-[60]',
    });
    this.showModal();
  }

  override updated(changedProps: PropertyValues) {
    if (changedProps.has('gallery') && this.gallery) {
      this.swiper = new Swiper('#swiper-big-gallery', {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        navigation: {
          nextEl: '.big-gallery-next-btn',
          prevEl: '.big-gallery-prev-btn',
        },
        modules: [Navigation],
        on: {
          activeIndexChange: (swiper) => {
            bigGalleryIndexChange$.next(swiper.activeIndex);
          },
        },
      });
      this.swiper.activeIndex = this.activeIndex;
      this.swiper.update();
    }
  }

  override connectedCallback() {
    modal$.subscribe((options) => {
      if (options?.type === 'gallery') {
        this.gallery = options.data.photos;
        this.activeIndex = options.data.index;
        if (this.modal) this.showModal();
      } else if (this.gallery) {
        // todo look for better solution (method from lib removes wrong backdrop)
        this.modal._destroyBackdropEl = function () {
          if (!this._isHidden) {
            document.querySelector('.big-gallery-backdrop').remove();
          }
        };
        this.modal.hide();
        this.gallery = undefined;
        this.activeIndex = 0;
        this.swiper.destroy();
        this.swiper = undefined;
      }
    });
    super.connectedCallback();
  }

  override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'element-big-gallery': ElementBigGallery;
  }
}
