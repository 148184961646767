export function getBiggestImageUrl(urls: Record<string, string>): string {
  if (!urls) {
    return undefined;
  }
  // Sorts the image data on the image width in decreasing order
  const sortedEntries = Object.entries(urls).sort(([keyA], [keyB]) => {
    const sizeA = parseInt(keyA.split('X')[0], 10);
    const sizeB = parseInt(keyB.split('X')[0], 10);
    return sizeB - sizeA;
  });

  // todo: remove when fixed in WebDB
  // https://camptocamp.atlassian.net/browse/GSCHM-4035 workaround, use smaller image instead of 1304X1072 size
  if (sortedEntries[0][0] === '1304X1072') return sortedEntries[1][1];

  return sortedEntries[0][1];
}

export function getSrcSet(urls: Record<string, string>): string {
  // srcset example: https://images.schweizmobil.ch/image/WL_062_12_019_nach_Oberalppass_R_F_1800x1440.jpg 1800w, https://images.schweizmobil.ch/image/WL_062_12_019_nach_Oberalppass_R_F_900x720.jpg 900w, https://images.schweizmobil.ch/image/WL_062_12_019_nach_Oberalppass_R_F_550x440.jpg 550w, https://images.schweizmobil.ch/image/WL_062_12_019_nach_Oberalppass_R_F_275x220.jpg 275w'
  let srcset = '';
  if (!urls) {
    return srcset;
  }
  const sortedEntries = Object.entries(urls).sort(([keyA], [keyB]) => {
    const sizeA = parseInt(keyA.split('X')[0], 10);
    const sizeB = parseInt(keyB.split('X')[0], 10);
    return sizeB - sizeA;
  });
  sortedEntries.forEach(([size, src]) => {
    srcset = srcset.concat(`${src} ${size.split('X')[0]}w, `);
  });

  return srcset.slice(0, -2); // Remove last comma and space
}
